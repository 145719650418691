<template>
<b-modal :id="id" :title="T(title)" ref="anomalyreport" :ok-title="T('Ok')" ok-only size="lg" @shown="updateList"> 
  <b-container fluid>
    <b-list-group class="userlist">
      <b-list-group-item v-for="s in anomalyList"
                         :key="s.ID">
        <b-row>
          <b-col cols="3">
            <div>{{s.Name}}</div>
          </b-col>
          <b-col cols="2">
            <div class="nottaken">
              <i class="fa fa-calendar-check"></i>
              {{s.notTaken}} {{T('not yet taken')}}
            </div>
          </b-col>

          <b-col cols="2">
            <div class="missed">
              <i class="fa fa-calendar-check"></i>
              {{s.Missed}} {{T('non-adherent')}}
            </div>
          </b-col>
          <b-col cols="5">
            <div v-if="s.Email">
              Email: <a :href="mailto(s.Email)">{{s.Email}}</a>
            </div>
            <div v-if="s.Phone">
              Phone: {{s.Phone}}
            </div>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </b-container>
</b-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    id: {
      required: true,
    },
    title: {
        default: "Missed Dose Report"
    },
  },
  computed: {
    ...mapState('patients', ['anomalyList'])
  },
  methods: {
    mailto: function(email) {
      return `mailto://${email}`;
    },
    updateList() {
      this.$store.dispatch('patients/updateAnomalyList')
    },
  },
}
</script>

<style lang="scss" >
@import '@/themes/jhg.scss';

userlist {
  max-height: 410px;
  overflow-y: scroll;
}
                 
.missed {
  i {
        color: theme-color("report-missed");
  }
}

.nottaken {
  i {
        color: theme-color("report-nottaken");
  }
}
</style>
  

