<template>
<b-container fluid>
  <b-row align-v="center" class="bg-secondary">
    <b-col id="hero-image">
      <img src="@/assets/logo-white_bg.svg" />
    </b-col>
    <b-col align-self="end" md="auto" class="p-4">
      <span class="title">{{ T("Adherence System") }}</span>
    </b-col>
  </b-row>
  <b-row>
    <b-col class="p-5">
      <b-form @submit="login">
      <b-card class="shadow mx-auto" style="width: 18rem">
        <template v-slot:header>
          <span>{{ T("Sign-in") }}</span>
        </template>
        <b-card-body>
          <b-alert variant="danger" :show="showFailureMessage">
            {{ T("Authentication Failed") }}. {{T("Please try again")}}.
          </b-alert>
          <b-form-group :label="T('Email Address')" label-for="login-username">
            <b-input-group>
              <template v-slot:prepend>
              <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
              </template>
              <b-form-input type="email" id="login-username" v-model="username" :placeholder="T('Enter email')" />
            </b-input-group>
          </b-form-group>
          <b-form-group :label="T('Password')" label-for="login-password">
              <b-input-group>
                <template v-slot:prepend>
                <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
                </template>
                <b-form-input type="password" id="login-password" v-model="password" :placeholder="T('Password')" />
              </b-input-group>
          </b-form-group>
        </b-card-body>
        <template v-slot:footer>
          <b-container>
            <b-row align-v="center">
              <b-col>
                <router-link to="/reset-password/request" class="small link">{{ T("Forgot Password") }}?</router-link>
              </b-col>
              <b-col md="auto">
                  <b-btn type="submit" variant="primary">{{ T('Login') }}</b-btn>
              </b-col>
            </b-row>
          </b-container>
        </template>
      </b-card>
      </b-form>
    </b-col>
  </b-row>
  <b-row align-h="end">
    <b-col cols="auto">
      <language-switcher />
    </b-col>
    <b-col>
    </b-col>
    <b-col  cols="auto" align-self="end">
      <small class="version">{{ T('Version')}} UI: {{ui_version}} API: {{api_version}}</small>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ui_version, api_version } from "@/app.config";
import LanguageSwitcher from "@/components/LanguageSwitcher";

export default {
  components: { LanguageSwitcher },
  data: function() {
    return {
      username: "",
      password: "",
      showFailureMessage: false,
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    ui_version() {
      return ui_version
    },
      api_version() {
        return api_version
    },
  },
  methods: {
    ...mapActions(["authenticate"]),
    async login(evt) {
      evt.preventDefault()
      await this.authenticate({ username: this.username,
                                password: this.password })
      if (this.isAuthenticated) {
        this.$router.push("/patients/list")
      } else {
        this.showFailureMessage = true;
      }
    }
  },
}
</script>

<style>
.link {
  color: black;
}
#hero-image img {
   max-width: 400px;
   margin: auto;
}
.title {
    font-family: Verdana,Geneva,sans-serif; 
    color: white;
    font-size: 2rem;
    padding-right: 2rem;
}
.version {
    color: gray;
    font-size: 1rem;
}
</style>
