<template>
<div>
  <nav-bar :page="this.$route.meta.title" />
  <router-view />
  <b-container fluid>
    <b-row align-h="end">
    <b-col cols="auto">
      <language-switcher />
    </b-col>
    <b-col>
    </b-col>
      <b-col  cols="auto" align-self="end">
        <small class="version">{{ T('Version')}} UI: {{ui_version}} API: {{api_version}}</small>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
  import NavBar from "@/components/NavBar";
  import LanguageSwitcher from "@/components/LanguageSwitcher";
  import { ui_version, api_version } from "@/app.config";

  export default {
    components: { NavBar, LanguageSwitcher },
    computed: {
      ui_version() {
        return ui_version
      },
      api_version() {
         return api_version 
      },
    }
  }

</script>

<style>
.version {
  color: gray;
  font-size: 1rem;
}
</style>

